<template>
    <v-card flat>
        <v-card-title>
            <span class="font-weight-bold primary--text">HPC integration</span>
        </v-card-title>
        <v-card-text>
            <v-select
                :disabled="fetchingSpaces || hpcSyncLoading"
                dense
                outlined
                v-model="selectedOrg"
                :items="orgNames"
                label="Select organization"
            ></v-select>
            <v-alert v-if="selectedOrg && selectedOrgHasHPC === false" class="my-5" type="warning" prominent text>
                <div style="max-width:900px" class="d-flex flex-column">
                    <span class="font-weight-bold">HPC not available</span>
                    <span v-if="isOrgManager" class="mt-1">
                        The current subscription of the selected organization does not include organization-wide HPC integration. To request a subscription
                        upgrade, please reach out to us via
                        <a @click="askForHelp">Intercom</a> or send an email to <a href="support@nuvolos.cloud">support@nuvolos.cloud</a>.
                    </span>
                    <span v-else>
                        The current subscription of the selected organization does not include organization-wide HPC integration. Please reach out to your
                        organization manager to submit a request for HPC integration.
                    </span>
                </div>
            </v-alert>
            <v-data-table v-if="selectedOrg" :search="search" :loading="fetchingSpaces || hpcSyncLoading" :headers="headers" :items="spaceList">
                <template v-slot:top>
                    <v-row justify="space-between" align="center">
                        <v-col cols="6">
                            <v-switch hide-details label="Show education spaces" v-model="showEducationSpaces"></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.long_id="{ item }">
                    {{ item.long_id }}
                </template>
                <template v-slot:item.beegfs_sync="{ item }">
                    <v-chip small v-if="item.beegfs_sync === true" color="success">
                        Activated
                    </v-chip>
                    <v-chip small v-else-if="item.beegfs_sync === false" color="error">
                        Deactivated
                    </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip v-if="item.beegfs_sync === true" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :disabled="selectedOrgHasHPC === false || isAdminRole(item.role) === false || fetchingSpaces"
                                :loading="hpcSyncLoading"
                                @click="updateFilesSynchronization(item.sid, false)"
                                color="success"
                                v-on="on"
                                icon
                                ><v-icon>sync</v-icon></v-btn
                            >
                        </template>
                        <span>Turn synchronization off</span>
                    </v-tooltip>
                    <v-tooltip v-else-if="item.beegfs_sync === false" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :disabled="selectedOrgHasHPC === false || isAdminRole(item.role) === false || fetchingSpaces"
                                :loading="hpcSyncLoading"
                                @click="updateFilesSynchronization(item.sid, true)"
                                color="error"
                                v-on="on"
                                icon
                                ><v-icon>sync_disabled</v-icon></v-btn
                            >
                        </template>
                        <span>Turn synchronization on</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { sortArray, isAdminRole, isManagerRole } from '@/utils'
import { enumsData } from '@/mixins/enums'

export default {
    mixins: [enumsData],
    data() {
        return {
            dialog: false,
            token: '',
            search: '',
            show: false,
            loading: false,
            selectedOrg: '',
            selectedOrgSpaces: [],
            hpcSyncLoading: false,
            fetchingSpaces: false,
            showEducationSpaces: false,
            headers: [
                {
                    text: 'Space name',
                    value: 'long_id'
                },
                { text: 'HPC status', value: 'beegfs_sync' },
                { text: 'Action', value: 'actions' }
            ]
        }
    },
    methods: {
        isAdminRole(role) {
            return isAdminRole(role)
        },
        fetchSpaces: function(oid) {
            this.$data.fetchingSpaces = true
            this.$axios
                .get(`/orgs/${oid}/spaces`)
                .then(response => {
                    if (response.data) {
                        this.$data.selectedOrgSpaces = response.data
                    }
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch spaces',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.fetchingSpaces = false
                })
        },
        fetchCurrentOrgSpaces() {
            if (this.$data.selectedOrg) {
                const org = this.userOrgs.find(org => org.long_id === this.$data.selectedOrg)
                this.fetchSpaces(org.oid)
            }
        },
        askForHelp() {
            window.Intercom('show')
        },
        updateFilesSynchronization: function(sid, newStatus) {
            this.$data.hpcSyncLoading = true
            this.$axios
                .patch(`/spaces/${sid}`, { beegfs_sync: newStatus })
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText:
                            newStatus === true
                                ? 'HPC file synchronization was successfully turned on!'
                                : 'HPC file synchronization was successfully turned off!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.fetchCurrentOrgSpaces()
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: newStatus === true ? 'Failed to turn HPC file synchronization on.' : 'Failed to turn HPC file synchronization off.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.hpcSyncLoading = false
                })
        }
    },
    computed: {
        ...mapState(['userOrgs']),
        orgNames() {
            if (this.userOrgs) {
                const orgs = this.userOrgs.map(org => org.long_id)
                return orgs
            }
            return []
        },
        selectedOrgHasHPC() {
            if (this.$data.selectedOrg) {
                const selectedOrgData = this.userOrgs.find(org => org.long_id === this.$data.selectedOrg)
                return !!selectedOrgData.hpc_enabled
            }
            return false
        },
        isOrgManager() {
            if (this.$data.selectedOrg) {
                const selectedOrgData = this.userOrgs.find(org => org.long_id === this.$data.selectedOrg)
                return isManagerRole(selectedOrgData.role)
            }
            return false
        },
        spaceList() {
            if (this.$data.selectedOrgSpaces && this.$data.showEducationSpaces) {
                const filteredSpaces = this.$data.selectedOrgSpaces.filter(space => space.space_type !== this.spaceTypes.VENDOR_SPACE)
                const sortedSpaces = sortArray(filteredSpaces, 'sid', 'descending', false)
                return sortedSpaces
            } else if (this.$data.selectedOrgSpaces) {
                const filteredSpaces = this.$data.selectedOrgSpaces.filter(
                    space => space.space_type !== this.spaceTypes.VENDOR_SPACE && space.space_type !== this.spaceTypes.EDUCATION_SPACE
                )
                const sortedSpaces = sortArray(filteredSpaces, 'sid', 'descending', false)
                return sortedSpaces
            }
            return []
        }
    },
    watch: {
        selectedOrg: function(nextVal, preVal) {
            if (nextVal && nextVal !== preVal) {
                this.fetchCurrentOrgSpaces()
            }
        }
    }
}
</script>
